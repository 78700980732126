





























import { Component, Vue } from "vue-property-decorator";
import Navbar from "@/components/home/Navbar.vue";
@Component({
    components: {
        "al-navbar": Navbar,
    },
})
export default class Header extends Vue {
    logobar = true;
    // created(){
    // }
    // mounted(){
    // }
}
