

















































































































































import { Vue, Component, Watch } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import CollapseTransition from "element-ui/lib/transitions/collapse-transition";
import RegistTypeDialog from "@/components/sign/RegistTypeDialog.vue";

@Component({
  components: {
    "el-collapse-transition": CollapseTransition,
    RegistTypeDialog,
  },
})
export default class Navbar extends Vue {
  @Getter("getToken") token: any;
  @Getter("getUser") getUser: any;
  @Action("getSetToken") setToken: any;
  @Getter("getLang") lang: any;
  @Action("getSetLang") setLangCommit: any;
  @Action("getSetAssessPermission") setAssessPermission: any; //设置store评审权限
  @Action("getSetUser") setUser: any;

  get showLoginBtn() {
    if (this.token) {
      return false;
    } else {
      return true;
    }
  }

  isshow = false; //是否显示下拉列表
  isUsercenterShow = false; //是否显示个人中心下拉列表

  registDialog: boolean = false; //是否显示注册种类选择弹窗

  //下拉列表判断actived
  get isDropmenuActived() {
    return (item: any) => {
      if (item.children) {
        // console.log(item.children);
        return item.children.some((e: any) => {
          return e.ref == this.activeIndex;
        });
      } else {
        return false;
      }
    };
  }

  //导航栏左侧
  get nav() {
    let opt = [
      {
        ref: "home",
        isLink: "scroll",
        target: "top",
        content: this.$t("scaffold.home.home"),
      },
      {
        ref: "cont_1",
        isLink: "scroll",
        target: "cont_1",
        content: this.$t("scaffold.home.aboutBestech"),
      },
      {
        ref: "ZGCView",
        isLink: "router",
        target: "ZGCView",
        content: this.$t("scaffold.home.zgc"),
      },
      // {
      //     ref: "standard",
      //     isLink: "scroll",
      //     target: "standard",
      //     content: this.$t("scaffold.home.standard"),
      // },

      // {
      //     ref: "proccess",
      //     isLink: "scroll",
      //     target: "proccess",
      //     content: this.$t("scaffold.home.proccess"),
      // },
      {
        ref: "benefit",
        isLink: "router",
        target: "benefit",
        content: this.$t("scaffold.home.projectRights"),
      },
      {
        ref: "cont_3",
        isLink: "dropdown",
        target: "cont_3",
        content: `${this.$t("scaffold.home.review")}`,
        icon: "angle-down",
        // control: "drop_1",
        children: [
          {
            ref: "first_activity",
            isLink: "router",
            target: "first_activity",
            content: `${this.$t("scaffold.home.2020review")}`,
          },
          {
            ref: "activityReview_2021",
            isLink: "router",
            target: "activityReview_2021",
            content: `${this.$t("scaffold.home.2021review")}`,
          },
          {
            ref: "activityReview_2023",
            isLink: "router",
            target: "activityReview_2023",
            content: `${this.$t("scaffold.home.2023review")}`,
          },

          // {
          //     ref: "projectlist2020",
          //     isLink: "router",
          //     target: "projectlist2020",
          //     content: `${this.$t("scaffold.home.2020project")}`,
          // },
          // {
          //     ref: "expertlist2020",
          //     isLink: "router",
          //     target: "expertlist2020",
          //     content: `${this.$t("scaffold.home.2020expert")}`,
          // },
        ],
      },
      {
        ref: "project",
        isLink: "dropdown",
        target: "project",
        content: `${this.$t("scaffold.home.project.name")}`,
        icon: "angle-down",
        // control: "drop_1",
        children: [
          {
            ref: "projectlist2023",
            isLink: "router",
            target: "projectlist2023",
            content: `${this.$t("scaffold.home.project.2023")}`,
          },
          {
            ref: "projectlist2021",
            isLink: "router",
            target: "projectlist2021",
            content: `${this.$t("scaffold.home.project.2021")}`,
          },
          {
            ref: "projectlist2020",
            isLink: "router",
            target: "projectlist2020",
            content: `${this.$t("scaffold.home.project.2020")}`,
          },
          {
            ref: "projectlistAll2021",
            isLink: "router",
            target: "projectlistAll2021",
            content: `${this.$t("scaffold.home.project.all")}`,
          },
        ],
      },
      {
        ref: "registBtn",
        isLink: "dropdown",
        icon: "angle-down",
        content: this.$t("scaffold.home.signNow_1"),
        children: [
          {
            ref: "register_project",
            isLink: "router",
            target: "register",
            params: {
              step: this.token !== "" ? "1" : "0",
              pid:
                this.getUser("project_id") !== ""
                  ? this.getUser("project_id")
                  : undefined,
            },
            content: this.$t("scaffold.home.register_project"),
          },
          {
            ref: "register_expert",
            isLink: "router",
            params: {
              step: this.token !== "" ? "1" : "0",
              eid:
                this.getUser("expert_id") !== ""
                  ? this.getUser("expert_id")
                  : undefined,
            },
            target: "register-expert",
            content: this.$t("scaffold.home.register_expert"),
          },
          {
            ref: "register_plain",
            isLink: "router",
            target: "register-plain",
            content: this.$t("scaffold.home.register_plain"),
          },
        ],
        // target: () => {
        //     // this.registDialog = true;
        //     this.$router.push({name:"register-plain"})
        // },
        // params: { step: 1 },
      },

      // {
      //     isLink: "router",
      //     ref: "registBtn",
      //     target: "register",
      //     params: { step: 1 },
      //     content: this.$t("scaffold.home.signNow")
      // }
    ];
    return opt;
  }

  //导航栏右侧
  get nav_login() {
    let opt: any = [
      {
        isLink: "btn",
        ref: "langBtn",
        target: this.changeLang,
        content: this.langtxt(),
        icon: "language",
      },
      {
        isLink: "|",
        ref: "|",
        target: "|",
        content: "|",
      },
      {
        isLink: "router",
        ref: "loginBtn",
        target: "login",
        content: this.$t("scaffold.login"),
        icon: "sign-in-alt",
      },
    ];

    if (this.showLoginBtn) {
      if (opt.findIndex((item: any) => item.ref == "loginBtn") == -1) {
        opt.push({
          isLink: "router",
          ref: "loginBtn",
          target: "login",
          content: this.$t("scaffold.login"),
          icon: "sign-in-alt",
        });
      } else {
      }
    } else {
      if (opt.findIndex((item: any) => item.ref == "loginBtn") != -1) {
        opt.splice(
          opt.findIndex((item: any) => item.ref == "loginBtn"),
          1
        );
        opt.push({
          ref: "usercenter",
          isLink: "dropdown",
          target: "login",
          content: `${this.$t("scaffold.usercenter")}`,
          icon: "angle-down",
          control: "usercenter",
          children: [
            {
              ref: "userinfo",
              isLink: "router",
              target: "userinfo",
              content: `${this.$t("scaffold.userinfo")}`,
            },
            // {
            //     ref: "register",
            //     isLink: "router",
            //     target: "register",
            //     content: `${this.$t("scaffold.projectRegist")}`,
            //     params: { step: "1" }
            // },
            {
              ref: "projectBasic",
              isLink: "router",
              target: "projectBasic",
              content: `${this.$t("scaffold.projectManage")}`,
            },
            {
              ref: "expertBasic",
              isLink: "router",
              target: "expertBasic",
              content: `${this.$t("scaffold.expertManage")}`,
            },
            {
              ref: "logout",
              isLink: "btn",
              target: this.logout,
              content: `${this.$t("scaffold.logout")}`,
            },
          ],
        });
      } else {
        opt.push({
          ref: "usercenter",
          isLink: "dropdown",
          target: "login",
          content: `${this.$t("scaffold.usercenter")}`,
          icon: "angle-down",
          control: "usercenter",
          children: [
            {
              ref: "userinfo",
              isLink: "router",
              target: "userinfo",
              content: `${this.$t("scaffold.userinfo")}`,
            },
            {
              ref: "register",
              isLink: "router",
              target: "register",
              content: `${this.$t("scaffold.projectRegist")}`,
              params: { step: "1" },
            },
            {
              ref: "logout",
              isLink: "btn",
              target: this.logout,
              content: `${this.$t("scaffold.logout")}`,
            },
          ],
        });
      }
    }

    return opt;
  }

  //汉堡菜单
  get nav_mobile() {
    let opt = [...this.nav, ...this.nav_login];
    opt.splice(
      opt.findIndex((item) => item.isLink === "|"),
      1
    );
    return opt;
  }

  dropmenuControllers = {}; //下拉列表控制器

  /**设置下拉列表控制器*/
  // @Watch(`nav_mobile`, { immediate: true, deep: true })
  setDropmenuControllers() {
    let key;
    while (key !== -1) {
      const dropmenuControllerskeys = Object.keys(this.dropmenuControllers);

      key = this.nav_mobile.findIndex((res: any) => {
        return (
          res.children &&
          res.children.length > 0 &&
          (dropmenuControllerskeys.length === 0 ||
            dropmenuControllerskeys.findIndex((val: any) => res.ref === val) ===
              -1)
        );
      });

      if (key !== -1) {
        // this.dropmenuControllers[this.nav_mobile[key].ref] = false;
        this.$set(this.dropmenuControllers, this.nav_mobile[key].ref, false);
      } else break;
    }
  }

  activeIndex: any = "0";
  isMobileNavShow = false;

  /* 返回切换语言按钮的文本 */
  langtxt() {
    if (this.lang == "zh") {
      return "Eng";
    } else if (this.lang == "en") {
      return "中";
    } else {
      return "中";
    }
  }

  /* 切换语言 */
  changeLang() {
    if (this.lang == "zh") {
      sessionStorage.setItem("lang", "en");
      this.setLangCommit("en");
    } else if (this.lang == "en") {
      sessionStorage.setItem("lang", "zh");
      this.setLangCommit("zh");
    } else {
      sessionStorage.setItem("lang", "zh");
      this.setLangCommit("zh");
    }
  }

  /*
   *  导航栏点击事件
   *  根据isLink参数判断是站内路由还是外部链接或是页面内锚点定位
   *  'link'为站外链接
   *  'router'为站内路由
   *  'scroll'为锚点定位
   */
  handleNavItem(e: any, item: any, key: any) {
    if (item.isLink == "link") {
      window.open(item.target);
    } else if (item.isLink == "router") {
      item.params
        ? this.$router.push({ name: item.target, params: item.params })
        : this.$router.push({ name: item.target });
      // this.$router.push({ name: item.target });
      this.activeIndex = item.ref;
      this.isshow = false;
      this.isUsercenterShow = false;
    } else if (item.isLink == "scroll") {
      if (this.$route.name == "home") {
        if (item.target == "top") {
          document.documentElement.scrollTo(0, 0);
          this.activeIndex = "home";
        } else if (item.target == "bottom") {
          this.handleScroll("footer");
          this.activeIndex = "home";
        } else {
          this.handleScroll(item.target);

          this.activeIndex = item.ref;
        }
      } else {
        // console.log(this.$router.push({ name: "home" }))
        this.$router.push({ name: "home" }).then((res: any) => {
          if (item.target == "top") {
            document.getElementsByClassName("container")[0].scrollTo(0, 0);
          } else if (item.target == "bottom") {
            this.handleScroll("footer");
          } else {
            this.handleScroll(item.target);
            this.activeIndex = item.ref;
          }
        });
      }
    } else if (item.isLink == "btn") {
      item.target();
    } else if (item.isLink == "|") {
    } else if (item.isLink == "dropdown") {
      (this as any).dropmenuControllers[item.ref] = (this as any)
        .dropmenuControllers[item.ref]
        ? false
        : true;
      //@ts-ignore
      // this[item.control] = this[item.control] ? false : true;
    } else {
      alert("系统出错：导航项没有给予正确的链接类型");
    }
  }

  /* 响应汉堡点击事件，切换汉堡菜单状态 */
  handleToggleNav(e: any) {
    if (this.isMobileNavShow == true) {
      this.isMobileNavShow = false;
    } else if (this.isMobileNavShow == false) {
      this.isMobileNavShow = true;
    }
  }

  /* 把导航栏数据注入到汉堡菜单 */
  makeMobileNav() {
    // this.nav_mobile = [...this.nav, ...this.nav_login];
  }

  /* 回到顶部 */
  async getScrollTop(el: any) {
    //获取元素距离页面顶部的距离
    // el.getBoundingClientRect();
    // console.log(el.offsetTop);
    return el.offsetTop - 60;
    // if (el.getBoundingClientRect().top < 0) {
    //     return 0 - Number(el.getBoundingClientRect().top);
    // } else {
    //     return Number(el.getBoundingClientRect().top) - 60;
    // }
  }

  /* 上窜 */
  smoothUp(distance: any, total: any, scroll_content: any, step: any) {
    if (distance > total) {
      let _this = this;
      distance -= step;
      scroll_content.scrollTo(0, distance);
      setTimeout(() => {
        _this.smoothUp(distance, total, scroll_content, step);
      }, 10);
    } else {
      window.scrollTo(0, total);
    }
  }

  /* 下跳 */
  smoothDown(distance: any, total: any, scroll_content: any, step: any) {
    /* 
            distance:滚动条距离顶部的距离；
            total: 目标元素距离页面顶部的距离；
            scroll_content: 滚动条容器；
            step:每一步跳的距离
        */
    if (distance < total) {
      let _this = this;
      distance += step;
      scroll_content.scrollTo(0, distance);
      setTimeout(() => {
        _this.smoothDown(distance, total, scroll_content, step);
      }, 10);
    } else {
      window.scrollTo(0, total);
    }
  }

  /* 响应滚动 */
  handleScroll(target: any) {
    let scrollBox = document.documentElement; //滚动条最外层父容器
    let el = document.getElementById(target); //目标元素
    let distance = scrollBox.scrollTop; //滚动条距离顶部的距离

    let _this = this;
    this.getScrollTop(el).then((res) => {
      //返回值为目标元素距离页面顶部的距离
      let step = res / 50;
      if (res > distance) {
        _this.smoothDown(distance, res, scrollBox, step);
      } else {
        let newTotal = distance - res;
        step = newTotal / 50;
        this.smoothUp(distance, res, scrollBox, step);
      }
    });
  }

  /* 滚动到对应模块设置导航栏actived */
  isScroll() {
    // let scrollTop = document.getElementsByClassName("container_home")[0]
    //     .scrollTop;
    let scrollTop = document.documentElement.scrollTop;

    const cont_length = document.getElementsByClassName("scrollerItem").length; //锚点数量
    for (let i = 0; i < cont_length; i++) {
      let cont_offsetTop = (document.getElementsByClassName("scrollerItem")[
        i
      ] as any).offsetTop;

      let cont_id = (document.getElementsByClassName("scrollerItem")[i] as any)
        .id;

      let cont_name = (document.getElementsByClassName("scrollerItem")[
        i
      ] as any).getAttribute("el");

      let cont_height = (document.getElementsByClassName("scrollerItem")[
        i
      ] as any).offsetHeight;

      if (
        cont_offsetTop <= scrollTop + 60 &&
        cont_offsetTop + cont_height >= scrollTop
      ) {
        this.activeIndex = cont_name;
      }
    }
  }

  /* 设置监听滚动条 */
  setScrollListener = () => {
    document.addEventListener("scroll", (this as any).isScroll(), true);
  };

  logout() {
    //注销
    sessionStorage.clear();
    this.setToken("");
    this.setAssessPermission([]);
    this.setUser({});
    this.$router.push({ name: "home" });
  }

  @Watch("$route")
  routeChange(to: any, from: any) {
    this.closeAllDropmenu();
    this.activeIndex = to.name;
  }

  /**关闭所有下拉菜单 */
  closeAllDropmenu() {
    let key = Object.values(this.dropmenuControllers).findIndex(
      (val: any) => val === true
    );
    (this as any).dropmenuControllers[
      Object.keys(this.dropmenuControllers)[key]
    ] = false;

    while (key !== -1) {
      (this as any).dropmenuControllers[
        Object.keys(this.dropmenuControllers)[key]
      ] = false;
      key = Object.values(this.dropmenuControllers).findIndex(
        (val: any) => val === true
      );
    }
  }

  //当前打开的的下拉菜单
  dropmenu_droping = "";

  //点击下拉菜单以外的地方关闭下拉菜单
  isclick(e: any) {
    // console.log(e.path)
    // console.log(e.path.contains(document.getElementsByClassName('dropmenu')[0]))
    // console.log(e.target)
    // console.log(e.target.contains(document.getElementsByClassName('dropmenu')[0]))
    // console.log(document.getElementsByClassName('dropmenu')[0].contains(e.target))
    // console.log(document.getElementsByClassName('dropmenu')[0])

    const ref = this.dropmenu_droping;
    if (
      document
        .getElementsByClassName(`dropdownbtn dropdown-${ref}`)[0]
        .contains(e.target)
    ) {
    } else {
      (this as any).dropmenuControllers[ref] = false;
    }
  }

  /**添加点击监听事件，用于点击下拉菜单外部关闭下拉菜单 */
  setClickListener(ref: any) {
    // document._params = ref;
    this.dropmenu_droping = ref;
    document.addEventListener("click", this.isclick, true);
  }

  /**拷贝，用于监听 */
  get dropmenuControllers_copy() {
    return JSON.parse(JSON.stringify(this.dropmenuControllers));
  }

  /**监听下拉列表
   * true时，添加点击事件，当点击菜单外面时关闭（控制器变为false）
   * false时，移除点击事件
   */
  @Watch("dropmenuControllers_copy", { deep: true })
  dropmenuControllersChange(nval: any, oval: any) {
    const valsArr = Object.values(nval);
    const keysArr = Object.keys(nval);
    const key = valsArr.findIndex((val: any) => val === true);

    if (key !== -1) {
      this.setClickListener(keysArr[key]);
    } else {
      if (Object.values(oval).findIndex((val: any) => val === true) !== -1) {
        document.removeEventListener("click", this.isclick, true);
      }
    }
  }

  mounted() {
    this.makeMobileNav();
    this.setDropmenuControllers();
    this.isScroll();
    this.setScrollListener();
    this.activeIndex = this.$route.name;
  }

  beforeDestroy() {
    document.removeEventListener("scroll", this.isScroll, true);
    document.removeEventListener("click", this.isclick, true);
  }
}
